<div class="modal-header">
    <a href="" class="float-right">
        <i class="fal fa-lg fa-times text-muted" (click)="cancel()"
            tooltip="Închide" tooltip-trigger="mouseenter"
            tooltip-placement="left" tooltip-append-to-body="true">
        </i>
    </a>
</div>

<div class="modal-body">
    <form (ngSubmit)="login()">
        <div class="form-group col-md-offset-1 col-md-10">
            <h4 class="modal-title">
                <div class="text-warning" style="font-size:19px;">
                    <br />
                    <strong class="text-center">
                        <i class="fad fa-2x fa-user-clock blink" style="margin-bottom:15px;"></i>
                        Sesiunea dumneavoastră a expirat
                    </strong>
                    <br />
                    <small class="text-warning">Conectați-vă pentru a continua de unde ați rămas</small>
                    <br /><br />
                </div>
            </h4>
        </div>
        <div class="form-group col-md-offset-1 col-md-10 text-center" ng-show="loadingMessage.length">
            <strong style="font-size:16px">
                <i class="fad fa-2x fa-spinner-third fa-spin" style="font-size:40px;"></i>
                <br /><br />{{loadingMessage}}
            </strong>
        </div>
        <div class="form-group col-md-offset-1 col-md-10" ng-show="!loadingMessage.length">
            <label class="control-label text-danger" ng-show="messageError.length">{{messageError}}</label>
            <div class="input-group">
                <label class="input-group-addon">
                    <i ng-class="{'fas' :utilizator.useRefreshTokens, 'fal' : !utilizator.useRefreshTokens }" class="fa-user"></i>
                </label>
                <input class="form-control" type="text" placeholder="Nume de utilizator"
                    ng-model="utilizator.userName"
                    data-toggle="tooltip" title="{{userName.length ? 'Utilizatorul dumneavoastră' : ''}}">
            </div>
        </div>
        <div class="form-group col-md-offset-1 col-md-10" ng-show="!loadingMessage.length">
            <div class="input-group">
                <label class="input-group-addon">
                    <i ng-class="{'fas' :utilizator.useRefreshTokens, 'fal' : !utilizator.useRefreshTokens }" class="fa-key"></i>
                </label>
                <input class="form-control" type="password" placeholder="Parola" ng-model="utilizator.password">
            </div>
            <!-- <div class="checkbox">
                <label>
                    <input type="checkbox" ng-model="utilizator.useRefreshTokens"> Reține datele de conectare
                </label>
            </div> -->
        </div>
        <div class="form-group col-md-offset-1 col-md-10 text-center" ng-show="!loadingMessage.length">
            <br />
            <input class="btn btn-primary" ng-show="!loadingMessage.length" type="submit" id="submit" value="Conectare" />
        </div>
    </form>
</div>

<div class="modal-footer"> </div>