<div>
  <header>
    <!-- <navbar></navbar> -->
    <!-- <ngb-tabset class="hidden-xs">
    <ngb-tab *ngFor="let tab of tabSet" (click)="onTabClick(tab)" [ngClass]="{'disabled': !isTabEnabled(tab), 'active': tab.url === activeRoute.url}">
        <ngb-tab title="Simple"><i class="{{tab.icon}}"></i>{{tab.caption}}</ngb-tab>
    </ngb-tab>
  </ngb-tabset>-->
    <!-- <route-controls></route-controls> -->
    <!-- <route-controls *ngIf=" settings.css.controlsPossition=='top'"></route-controls> -->
  </header>

  <!-- <route-controls *ngIf=" settings.css.controlsPossition=='right'" style=" width: min-content;height: 100vh;"></route-controls> -->
  <!-- <br /> -->
  
  <section id="main" class="container-fluid my-3" style="width: 98%">
    <!-- <div class="row" data-ui-view="lazyLoadView"></div> -->
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </section>
  
</div>

<scroll-top></scroll-top>
