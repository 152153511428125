import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { AppAccess } from './app-access';
// import { AppAccess } from './main/config/app.access';
import { AppGuard, AuthGuard, HomeSettingsGuard } from './main/config/auth.guard';
import { environment } from 'src/environments/environment.dev';

import { Home } from './main/home/home';
import { Login } from './main/login/login';
import { UpdateStatus } from './main/update.status/update.status';
import { PageNotFound } from './main/pageNotFound';

const main: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'login', component: Login, canActivate: [AppGuard], data: { title: 'Login', icon: 'fa-user' } },
  { path: 'ng/home', component: Home, canActivate: [AuthGuard, HomeSettingsGuard], data: { title: 'Home', icon: 'fa-home' } },
  { path: 'update/status', component: UpdateStatus, canActivate: [AuthGuard], data: { title: 'Update status', icon: 'fa-home' } },
];
const devRoutes: Routes = [
  {
    path: 'ng/dev', canActivate: [AuthGuard],
    data: { access: AppAccess.dev, title: 'Dev', icon: 'fa-cogs' },
    loadChildren: () => import('./module/_dev/dev.module').then(m => m.DevModule),
  },
];
const routes: Routes = [
  {
    path: 'ng/user', canActivate: [AuthGuard],
    data: { access: AppAccess.user, title: 'Utilizator', icon: 'fa-user' },
    loadChildren: () => import('./module/user/user.module').then(m => m.UserModule),
  }, {
    path: 'help', canActivate: [AuthGuard],
    data: { access: AppAccess.admin, title: 'Help', icon: 'fa-user' },
    loadChildren: () => import('./module/help/help.module').then(m => m.HelpModule),
  }, {
    path: 'ng/admin', canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    data: { access: AppAccess.admin, title: 'Admin', icon: 'fa-user' },
    loadChildren: () => import('./module/admin/admin.module').then(m => m.AdminModule),
  }, {
    path: 'document', canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    data: { access: AppAccess.documente, title: 'Document', icon: 'fa-user' },
    loadChildren: () => import('./module/document/document.module').then(m => m.DocumentModule),
  }, {
    path: 'nomenclator', canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    data: { access: AppAccess.nomenclatoaregenerale, title: 'Nomenclator', icon: 'fa-user' },
    loadChildren: () => import('./module/nomenclator/nomenclator.module').then(m => m.NomenclatorModule),
  }, {
    path: 'resum', canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    data: { access: AppAccess.resum, title: 'Resurse Umane', icon: 'fa-user' },
    loadChildren: () => import('./module/resum/resum.module').then(m => m.ResumModule),
  },
  { path: '**', pathMatch: 'full', component: PageNotFound }, // must be last
];

@NgModule({
  declarations: [Home, Login, PageNotFound, UpdateStatus],
  exports: [RouterModule],
  imports: [
    CommonModule, FormsModule, NgbCollapseModule,
    RouterModule.forRoot(
      environment.production ? [...main, ...routes] : [...main, ...devRoutes, ...routes],
      {
        // onSameUrlNavigation: 'reload', // useHash: true, enableTracing: true,
      }
    )
  ],
})
export class AppRoutingModule { }
