import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from 'core';

var idxGrid, idxGridOld;
function findGridStyleIndex() {
    for (let i = 0; i < document.styleSheets.length; i++) {
        const rules = document.styleSheets.item(i).cssRules;
        if (rules.length) {
            if (rules[0].cssText.startsWith('ag-grid,')) { idxGrid = i }
            if (rules[0].cssText.startsWith('ag-grid-ng2')) { idxGridOld = i }
        }
    }
}

@Component({
    selector: 'page-not-found', template: '',
    // template: `<h1>ng2 Page Not Found <a href="admin/ngSample">test page</a></h1>` // probabil e ruta de Angular 1 !
})
export class PageNotFound {
    constructor(router: Router, log: LogService) {
        console.log('enable old grid');
        if (!idxGrid) { findGridStyleIndex() }
        console.log('ng2', idxGrid, idxGridOld);
        void (document.styleSheets.item(idxGrid).disabled = true); //aggrid new
        void (document.styleSheets.item(idxGridOld).disabled = false); //aggrid old

        // log.info('Pagina inexistenta!');
        // router.navigate(['home']);
    }
}