import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService, User } from '../../services/UserService';
import { LogService } from '../../services/LogService';

@Component({
    selector: 'dialog-login', templateUrl: './dialog.login.html',
})
export class ModalAutentificareUser implements OnInit {
    constructor(private activeModal: NgbActiveModal, private user: UserService, private log: LogService) { }

    @Input() userName: string;
    utilizator: User.IAuthentication = { userName: '' };

    tooltip: string = '';
    messageError: string = '';
    loadingMessage: string = '';

    ngOnInit() {
        this.utilizator.userName = this.userName || "";
    }

    cancel() {
        this.loadingMessage = "Veți fi deconectat în câteva secunde";
        //this.user.logOut();
        this.activeModal.dismiss({ status: 401 });
    }

    login() {
        if (this.checkLoginData(this.utilizator)) {
            this.user.getTokenRequest(this.utilizator).then(response => {
                this.loadingMessage = `Vă rugăm așteptați`;
                setTimeout(() => {
                    this.log.success("Autentificat cu succes");
                    this.activeModal.close({ data: response });
                }, 1000);
            }, (err) => { this.onError(this.onRequestError(err)) })
        } else {
            this.onError(this.onRequestError({ data: "Completați nume de utilizator și parola!" }))
        }
    }
    checkLoginData(loginData: any): boolean {
        return (loginData
            && loginData.userName && loginData.userName.length
            && loginData.password && loginData.password.length) ? true : false;
    }

    onRequestError(err: any): { data: any, status: number } {
        var error: { data: any, status: number } = { data: "", status: 400 };
        try {
            if (err)
                if (typeof err == 'object') {
                    if (err.data) error.data = err.data;
                    if (err.status) error.status = err.status;
                } else if (typeof err == 'string') {
                    error.data = err;
                } else throw err;
            this.log.error(error.data, err, true);
            return error;
        } catch (e) { return err; }
    }

    private onError(err) {
        this.messageError = (err && err.data) ? err.data : 'Utilizator sau parolă greșită!';
        this.loadingMessage = "";
    }
}