import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LogService } from 'core';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private injector: Injector, private http: HttpClient) { }

	timeoutId;
	log: LogService;
	location: LocationStrategy;
	acumulator = new Set();

	// if (!navigator.onLine) { return 'No Internet Connection' }
	handleError(error) {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}
		if (!this.log) { this.log = this.injector.get(LogService) }
		if (!this.location) { this.location = this.injector.get(LocationStrategy) }

		const message = error.error || (error.rejection && error.rejection.message || error.rejection) || error.message;
		if (message == 'close') { return }

		this.log.error(`[Sigma]: ${message || ''}`, error.statusText || null, error, {
			timeOut: 8000, progressBar: true
		});
		// this.acumulator.add({ message: message, url: this.location.path(), stack: error.stack, });

		// if (!this.timeoutId) {
		// 	this.timeoutId = setTimeout(() => {
		// 		this.http.post('/admin/client/error', this.acumulator).subscribe({
		// 			next: res => { }, error: err => { }
		// 		});
		// 		this.acumulator.clear();
		// 		this.timeoutId = null;
		// 	}, 5000)
		// }
	}
}