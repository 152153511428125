import { trigger, animate, transition, style, keyframes, state } from '@angular/animations';

// attach this animation name to an html/:host element using the [@triggerName]    

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
    // route 'enter and leave (<=>)' transition
    transition('*<=>*', [
        style({ opacity: 0 }), // css styles at start of transition
        animate('0.4s', style({ opacity: 1 })) // animation and styles at end of transition
    ])
]);
export const fadeInBumpUpAnimation = trigger('fadeInSlideUpAnimation', [
    transition('* => *', [
        style({ opacity: 0 }),
        animate('.3s ease', style({ opacity: 1, transform: 'translateY(-10px)' }))
    ])
]);

export const fadeInSlideUpAnimation = trigger('fadeInSlideUpAnimation', [
    transition('* => *', [
        state('in', style({
            opacity:0,
            'animation-iteration-count': 1,
            'transform-origin': '50% 50%',
            'animation-fill-mode': 'forwards',
        })),
        animate('1s ease', keyframes([
            style({ offset: 0, opacity: 0, transform: 'translate(0px,15px)' }),
            style({ offset: 1, opacity: 1, transform: 'translate(0px,0px)' }),
        ]))
    ])
]);

// windows 8 style
export const fadeInSlideLeftAnimation = trigger('fadeInSlideLeftAnimation', [
    transition('* => *', [
        style({
            opacity: 0,
            transform: 'translateX(2em)',
            'animation-fill-mode': 'forwards'
        }),
        animate('.3s ease-in-out', keyframes([
            style({ offset: 0, opacity: 0, transform: 'translateX(2em)' }),
            // style({ offset: .5, opacity: 1, }),
            style({ offset: 1, opacity: 1, transform: 'translateX(0)' }),
        ]))
    ])
]);

export const fadeInSlideLeftAnimation2 = trigger('fadeInSlideLeftAnimation2', [
    transition('* => *', [
        style({
            opacity:0,
            'transform-origin': '50% 50%',
            'animation-fill-mode': 'forwards',
        }),
        animate('1s ease', keyframes([
            style({ offset: 0, opacity: 0, transform: 'translate(30px,0px)' }),
            style({ offset: 1, opacity: 1, transform: 'translate(0px,0px)' }),
        ]))
    ])
]);