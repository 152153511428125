import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebSoketService, LogService } from 'core';

@Component({
    selector: 'update-status', templateUrl: './update.status.html'
})
export class UpdateStatus implements OnInit, OnDestroy {
    constructor(private http: HttpClient, private ws: WebSoketService, private log: LogService) { }

    errorOnly: boolean;
    activeLog: string = 'files';
    private subscription;

    messages = {
        files: { progress: [], detail: [] },
        sql: { progress: [], detail: [] },
        reports: { progress: [], detail: [] },
        server: { progress: [], detail: [] }
    };

    get message() {
        return this.messages[this.activeLog].progress.map(item => item.message).join('\n')
    }
    get detail() {
        return this.messages[this.activeLog].detail.slice(15)
    }
    get errors() {
        return this.messages[this.activeLog].progress
            .concat(this.messages[this.activeLog].detail)
            .filter(item => item.isError)

    }

    ngOnDestroy() {
        this.subscription && this.subscription.unsubscribe()
    }
    ngOnInit() {
        this.subscription = this.ws.send('register').subscribe(data => {
            if (typeof data === 'string') {
                try { data = JSON.parse(data) } catch (error) { }
            }
            this.activeLog = data.type;
            if (data.isDetail) {
                this.messages[data.type].detail.unshift(data)
            } else {
                this.messages[data.type].progress.push(data)
            }
        }, (error) => { this.log.error(error.message) })
    }

    getStatus() {
        this.http.post('/update/status', { action: 'status' })//.subscribe(console.log)
    }
    hasError(type: string): boolean {
        return this.messages[type].progress.some(item => item.isError) ||
            this.messages[type].detail.some(item => item.isError)
    }
}