import { Component, OnInit } from '@angular/core';
import { AppSettings, StyleManager, UserService, RoutingService } from 'core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private styleManager: StyleManager, private router: RoutingService,
    protected settings: AppSettings, user: UserService) {
    if (!user.isAuthenticated) { user.logOutNew() }
  }

  tabs: any[];
  ngOnInit() { }

  protected isActiveAnimation(route) {
    return this.styleManager.css.isActiveAnimation && route && route.activatedRouteData
  }
  protected isNavbarVertical() {
    return this.styleManager.css.navbarPosition == 'fixed-left'
  }
  protected onRouterOutletActivate(componentRef) {
    this.router.setActiveRoute(componentRef);
  }
}