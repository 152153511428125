import { Component, Input } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'help-container',
	template: `
		<div class="offcanvas-header">
			<h5 class="offcanvas-title">Help center</h5>
			<button type="button" class="btn-close text-reset" aria-label="Close"
				(click)="activeOffcanvas.dismiss()"></button>
		</div>
		<div class="offcanvas-body">
			<div>Hello {{ name }}</div>
			<button type="button" class="btn btn-outline-dark" (click)="activeOffcanvas.close()">Close</button>
		</div>`,
})
export class HelpContainer {
	constructor(public activeOffcanvas: NgbActiveOffcanvas) {}

	@Input() name;

}