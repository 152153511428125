import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import {
    UserService, ReportService, DataService, AppSettings, DialogService,
    RoutingService, ConfigService, StyleManager, Query, Config, States
} from 'core';
import { HelpContainer } from './navbar.helpContainer';
import { FeedbackModal } from './navbar.feedback';

@Component({
    selector: 'navbar', templateUrl: './navbar.html',
    styles: ['.no-arrow.dropdown-toggle::after {display:none;}']
})
export class Navbar implements OnInit {
    constructor(private router: Router, private http: HttpClient,
        private data: DataService, private user: UserService,
        private config: ConfigService, public report: ReportService,
        private routing: RoutingService, private settings: AppSettings,
        private dialog: DialogService, public style: StyleManager,
        private offcanvasService: NgbOffcanvas) {

        routing.routeChange().pipe(
            mergeMap(route => route.data),
        ).subscribe((data: { [name: string]: any }) => {
            routing.setTitle(data.title);
            this.isConfigReadonly = data.isConfigReadOnly;
            this.icon = data.icon;
        });
        user.onLoginComplete.subscribe(currentUser => {
            if (currentUser) { this.getSuportOnline() }
        });
        // user.onLoginComplete.subscribe(currentUser => {
        //     if (currentUser) {
        //         this.getSuportOnline();
        //         let attempts = 0;
        //         var interval = setInterval(() => {
        //             if (attempts >= 500 && interval) { clearInterval(interval) }
        //             if (settings.loaded) {
        //                 clearInterval(interval);
        //                 interval = null;
        //                 this.getSuportOnline();
        //             } else { attempts++ }
        //         }, 500)
        //     }
        // });
    }

    icon: string;
    tabs: any[] = [];
    favorites: any[] = [];
    notificari: any[] = [];

    isConfigReadonly: boolean;
    collapseNotify: boolean = true;
    collapseUserNav: boolean = true;
    collapsePrintOp: boolean = true;
    collapseFavorites: boolean = true;

    private suportOnlineParams = "";
    get suportUrl() { return `http://www.indecosoft.ro/cerc/index.php${this.suportOnlineParams}` }

    get title() { return this.routing.title }
    get isBusy(): boolean { return this.http['isBusy'] }
    get isAuthenticated() { return this.user.isAuthenticated }
    get userName() { return this.user.identity.name || this.user.identity.userName }

    isHelpOpen: boolean;
    isFullScreenActive: boolean;
    get fullScreenActive(): boolean {
        if (this.isFullScreenActive === undefined) { return false }
        return document.fullscreenElement === null
    }

    ngOnInit() { }

    navigateTo(route) { this.router.navigate([route]) }
    reportIcon(format: string) { return this.report.getFormatIcon(format) }
    setReportFormat(format: string) {
        this.report.format = format;
        this.collapsePrintOp = true;
    }
    logOut() {
        this.user.logOutNew();
    }

    get providerClass() {
        switch (this.user.identity.provider) {
            case 'fab microsoft':
            case 'fab activeDirectory':
                return "fab fa-windows";
            case 'google': return "fab fa-google-plus";
            case 'facebook': return "fab fa-facebok";
            case 'yahoo': return "fab fa-yahoo";
            default: return `fad fa-${(this.user.identity.provider === 'Indeco' ? 'user' : this.user.identity.provider)}`;
        }
    }

    getFavorites() {
        this.config.get(Config.favorites$Administraresistem$Administraresistem).then(fav => this.favorites = fav)
    }
    getSuportOnline() {
        //Query.user.getDateForSuport()
        this.data.executeQueryNew(Query.user.getUserBtn()).then(data => {
            if (data.length > 0) {
                this.suportOnlineParams = `?ref=${data[0].cui}&ref2=${data[0].nume}`;
                if (data[0].fullName) this.suportOnlineParams += `&nume=${data[0].fullName}`;
                if (data[0].telefon) this.suportOnlineParams += `&tel=${data[0].telefon}`;
                if (data[0].regiune) this.suportOnlineParams += `&jud=${data[0].regiune}`;
                if (data[0].localitate) this.suportOnlineParams += `&loc=${data[0].localitate}`;
            }
        })
    }

    openHelp(evt: Event) {
        const offcanvasRef = this.offcanvasService.open(HelpContainer, { position: 'end' });
        offcanvasRef.componentInstance.name = 'World';
        this.collapseUserNav = true;
    }

    openModal() {
        this.dialog.custom(FeedbackModal, {}, { size: 'lg' });
        this.collapseUserNav = true;
    }

    notificationClass(type: string): string {
        switch (type) {
            case 'notification': return '';
            case 'report': return 'fa-file';
            default: return 'fa-file';
        }
    }
    viewNotification(item) {
        if (item == 'all') {
            // this.routing.goNext([States.user.notificari])
        } else { }
    }
    openNotificare(item) {

    }

    toggleFullScreen() {
        this.isFullScreenActive ? this.closeFullScreen() : this.openFullScreen();
        this.isFullScreenActive = !this.isFullScreenActive;
        this.collapseUserNav = true;
    }
    openFullScreen() {
        let elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen()
        } else if (elem['mozRequestFullScreen']) { /* Firefox */
            elem['mozRequestFullScreen']()
        } else if (elem['webkitRequestFullscreen']) { /* Chrome, Safari and Opera */
            elem['webkitRequestFullscreen']()
        } else if (elem['msRequestFullscreen']) { /* IE/Edge */
            elem['msRequestFullscreen']()
        }
    }
    closeFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document['mozCancelFullScreen']) { /* Firefox */
            document['mozCancelFullScreen']()
        } else if (document['webkitExitFullscreen']) { /* Chrome, Safari and Opera */
            document['webkitExitFullscreen']()
        } else if (document['msExitFullscreen']) { /* IE/Edge */
            document['msExitFullscreen']()
        }
    }
}