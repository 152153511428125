<div class="container">
  <div class="card">
    <div class="card-header">
      <h3 class="panel-title">Status update aplicatie</h3>
    </div>

    <div class="card-body row">
      <div class="col-sm-4">
        <div class="list-group">
          <button type="button" class="list-group-item list-group-item-action" [class.active]="activeLog=='files'" (click)="activeLog='files'">
            Fisiere aplicatie<i *ngIf="hasError('files')" class="pt-1 fa fa-1x fa-exclamation-triangle text-warning float-right"></i>
          </button>
          <button type="button" class="list-group-item list-group-item-action" [class.active]="activeLog=='sql'" (click)="activeLog='sql'">
            Sql server<i *ngIf="hasError('sql')" class="pt-1 fa fa-1x fa-exclamation-triangle text-warning float-right"></i>
          </button>
          <button type="button" class="list-group-item list-group-item-action" [class.active]="activeLog=='reports'" (click)="activeLog='reports'">
            Rapoarte<i *ngIf="hasError('reports')" class="pt-1 fa fa-1x fa-exclamation-triangle text-warning float-right"></i>
          </button>
          <button type="button" class="list-group-item list-group-item-action" [class.active]="activeLog=='server'" (click)="activeLog='server'">
            Server<i *ngIf="hasError('server')" class="pt-1 fa fa-1x fa-exclamation-triangle text-warning float-right"></i>
          </button>
        </div>
      </div>

      <div class="col-sm-8">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" [(ngModel)]="errorOnly" name="errorOnly" id="errorOnly" />
          <label class="form-check-label" for="errorOnly">Afiseaza doar erorile</label>
        </div>

        <div class="border border-secondary rounded overflow-auto"
          style="min-height:400px; max-height:600px; padding:5px 10px 5px 10px">
          <div style="white-space:pre-line">{{message}}</div>
          <hr *ngIf="!errorOnly && detail.length > 0" />
          <ul *ngIf="!errorOnly && detail.length > 0">
            <li>Total: {{detail.length}}</li>
            <li *ngFor="let item of detail">{{item.message}}</li>
            <li *ngIf="detail.length>15">...</li>
          </ul>
          <ul *ngIf="errorOnly">
            <li *ngFor="let item of errors">{{item.message}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>