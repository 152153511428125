<div class="modal-header">
    <h5>Feedback</h5>
</div>
<div class="modal-body">
    <p class="text-center">Va rugam descrieti cat mai detaliat problema dvs.</p>
    <p class="mb-1">Modul: {{modul}}</p>
    <p>Pagina: {{page}}</p>

    <textarea class="form-control" rows="5" [(ngModel)]="problema" name="problema" required></textarea>
</div>
<div class="modal-footer">
    <button class="btn btn-success" (click)="send()" [disabled]="!problema">
        <i class="fal fa-envelope fa-1x"></i>&nbsp;Trimite
    </button>
    <button class="btn btn-danger" (click)="cancel()">
        <i class="fal fa-undo fa-1x"></i>&nbsp;Renunță
    </button>
</div>