import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from '@angular/forms';

import { DataServiceWebApiAdapter } from 'breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderODataAdapter } from 'breeze-client/adapter-uri-builder-odata';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';
import { AjaxPostWrapper } from 'breeze-client/adapter-ajax-post';
import { HttpClient } from '@angular/common/http';

import { FileUploadModule } from 'ng2-file-upload'
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule, ToastNoAnimation } from 'ngx-toastr';
import { DatepickerModule } from "core/datepicker";

import { BarcodeService } from "./services/BarcodeService";
import { ConfigService, ConfigServiceNew } from "./services/ConfigService";
import { CursValutarService } from "./services/CursValutarService";
import { DataService } from "./services/DataService";
import { DialogService } from "./services/DialogService";
import { FileService } from "./services/FileService";
import { FileReaderService } from './services/FileReaderService';
import { LogService } from "./services/LogService";
import { ReportService } from "./services/ReportService";
import { UserService } from "./services/UserService";
import { AppSettings } from './services/AppSettings';
import { ValidationService } from "./services/ValidationService";
import { WebSoketService } from "./services/WebSocketService";
import { ControlsService } from "./services/ControlsService";
import { StyleManager } from "./services/StyleManager";
import { NotificationService } from "./services/NotificationService";

import { FileViewer } from './controls/modal/dialog.file';
import { ConfirmDialog } from "./controls/modal/dialog.confirm";
import { IncetareInregistrare } from './controls/modal/dialog.incetare';
import { ReportFileTypeSelection } from './controls/modal/dialog.report';
import { ProgressDialog } from "./controls/modal/dialog.progress";
import { ModalAutentificareUser } from "./controls/modal/dialog.login";

@NgModule({
    imports: [
        FormsModule, BrowserAnimationsModule, // needed for toastr or use ToastNoAnimation or NoopAnimation
        NgbModalModule, NgbTooltipModule,
        ToastrModule.forRoot({ positionClass: 'toast-bottom-right', timeOut: 5000, }),
        FileUploadModule, DatepickerModule,
    ],
    declarations: [
        FileViewer, ConfirmDialog, ProgressDialog,
        IncetareInregistrare, ReportFileTypeSelection,
        ModalAutentificareUser
    ],
    providers: [
        StyleManager, ControlsService,
        BarcodeService, CursValutarService,
        ConfigService, ConfigServiceNew, DataService,
        DialogService, LogService, NotificationService,
        UserService, AppSettings, ReportService,
        FileService, FileReaderService, 
        ValidationService, WebSoketService,
    ],
})
export class CoreModule {
    constructor(http: HttpClient) {
        // order matters
        ModelLibraryBackingStoreAdapter.register();
        UriBuilderODataAdapter.register();
        // AjaxHttpClientAdapter.register(http);
        AjaxPostWrapper.wrapAjax(AjaxHttpClientAdapter.register(http));
        DataServiceWebApiAdapter.register();
    }
}