import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'core';

@Component({
    selector: 'navbar-unitate',
    templateUrl: './navbar.unitate.html'
})
export class NavbarUnitate implements OnInit {
    constructor(private settings: AppSettings) {
        // this.settings.subscribe(changed => {
        //     if (changed && !this.anLuna)
        //         this.anLuna = new Date(this.settings.an, this.settings.luna - 1)
        // })
    }

    mode = 'month';
    isOpen: boolean;
    current = new Date();
    dateOptions = { "min-mode": "month", "show-weeks": false, "startingDay": 1 };

    anLuna: Date;
    get numeUnitate() { return 'selectie unitate' }
    get an() { return this.settings['an'] }
    get luna() { return this.settings['luna'] }

    ngOnInit() { this.isOpen = !this.settings['anLuna'] }
    setUnitate() { }
    setAnLuna() {
        var month = this.anLuna.getMonth() + 1;
        // Unitate.anLuna = parseInt('' + this.anLuna.getFullYear() + (month < 10 ? '0' + month : month));
        // this.config.set(Config.anLuna, Unitate.anLuna);
        // this.$state.reload();
    }
}