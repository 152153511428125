<div class="offcanvas-header">
    <h5 class="offcanvas-title text-center">Butoane</h5>
    <button type="button" class="btn-close text-reset" aria-label="Close"
        (click)="activeOffcanvas.close()">
    </button>
</div>
<hr class="my-1" />
<div class="offcanvas-body">
    <!-- <route-controls-btn><hr class="my-2" /></route-controls-btn> -->

    <div *ngFor="let btn of buttons" class="text-end">
        <hr *ngIf="btn.type === 'div'" class="my-2" /> <!--divider-->

        <!--button-->
        <button type="button" class="btn mb-1" [ngClass]="controlSize('btn', btn)"
            *ngIf="btn.type === 'cmd' && !btn.btns && (!btn.splitCaret || (btn.splitCaret && (!btn.btns || (btn.btns && !btn.btns.length)))) && isVisible(btn)"
            [disabled]="isDisabled(btn)" (click)="onClick(btn)">
            <i class="far fa-1x" [ngClass]="btn.icon || 'fa-file-o'"></i>
            <span class="hidden-xs">&nbsp;{{btn.caption}}</span>
        </button>

        <!--dropdown split caret-->
        <div class="btn-group mb-1"
            *ngIf="btn.type === 'cmd' && btn.splitCaret && btn.btns && btn.btns.length > 0 && isVisible(btn)">
            <button type="button" class="btn" [ngClass]="controlSize('btn', btn)"
                [disabled]="isDisabled(btn)" (click)="onClick(btn)" [attr.title]="btn.tooltip">
                <i class="far fa-1x" [ngClass]="btn.icon"></i>
                <span class="hidden-xs">&nbsp;{{btn.caption}}</span>
            </button>
            <div class="btn-group" role="group" [ngClass]="controlSize('btn-group')"
                *ngIf="btn.type === 'cmd' && btn.btns && btn.btns.length > 0"
                display="dynamic" placement="bottom-right" ngbDropdown>

                <button type="button" class="btn dropdown-toggle-split" ngbDropdownToggle
                    [ngClass]="btn.class || 'btn-primary'"></button>
                <div ngbDropdownMenu>
                    <button ngbDropdownItem *ngFor="let subcmd of btn.btns"
                        [disabled]="isDisabled(subcmd)" (click)="onClick(subcmd)">
                        <i class="far fa-fw fa-1x" [ngClass]="subcmd.icon"></i>&nbsp;{{subcmd.caption}}
                    </button>
                </div>
            </div>
        </div>

        <!--dropdown caret-->
        <div class="btn-group mb-1" role="group" [ngClass]="controlSize('btn-group')"
            *ngIf="btn.type === 'cmd' && !btn.splitCaret && btn.btns && btn.btns.length > 0 "
            display="dynamic" placement="bottom-right" ngbDropdown>

            <button type="button" class="btn" [ngClass]="btn.class || 'btn-primary'" [disabled]="isDisabled(btn)"
                [attr.title]="btn.tooltip" placement="bottom" triggers="mouseenter" ngbDropdownToggle>
                <i class="far fa-1x" [ngClass]="btn.icon"></i>
                <span class="hidden-xs">&nbsp;{{btn.caption}}</span>
            </button>
            <div ngbDropdownMenu>
                <button ngbDropdownItem *ngFor="let subcmd of btn.btns"
                    [disabled]="isDisabled(subcmd)" (click)="onClick(subcmd)">
                    <i class="far fa-fw fa-1x" [ngClass]="subcmd.icon"></i>&nbsp;{{subcmd.caption}}
                </button>
            </div>
        </div>
    </div>
</div>