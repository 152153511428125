<div class="row mx-auto">
    <div class="list-group col-lg-3" role="tablist">
        <ng-container *ngFor="let route of menu; let i = index">
            <button type="button" class="list-group-item list-group-item-action pt-1 pb-2"
                (click)="setActive(route, i)" [class.active]="activeLink==i">
                <i class="fa pe-2 d-lg-none" [ngClass]="!route.isCollapse ? 'fa-chevron-right' : 'fa-chevron-down'"></i>
                <i class="fal fa-fw fa-1x" [ngClass]="route.icon || 'fa-folder'"></i>&nbsp;{{route.title}}
            </button>
            <div *ngIf="style.isMobile" class="list-group" [ngbCollapse]="!route.isCollapse">
                <ng-container *ngFor="let module of route.child;let j=index">
                    <button type="button" class="list-group-item pt-1 pb-2 text-start" (click)="module.isCollapse = !module.isCollapse">
                        <i class="fa pe-1" style="text-indent: 1.5rem;" [ngClass]="!module.isCollapse ? 'fa-chevron-right' : 'fa-chevron-down'"></i>
                        <i class="fal fa-fw fa-1x" [ngClass]="module.icon || 'fa-folder-open'"></i>&nbsp;
                        <span style="text-transform: capitalize;">{{module.title}}</span>
                    </button>
                    <div class="list-group" [ngbCollapse]="!module.isCollapse">
                        <button type="button" class="list-group-item text-start pt-1 pb-2" *ngFor="let link of module.child"
                            (click)="$event.preventDefault(); onSelect(link)">
                            <i class="fa fa-1x" style="text-indent: 3rem;" [ngClass]="link.icon || 'fas fa-file-exclamation'"></i>&nbsp;
                            <span style="text-transform: capitalize;">{{link.title}}</span>
                        </button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div class="card col-lg-9" *ngIf="!style.isMobile" [@fadeInOutAnimation]="activeLink">
        <div class="card-body row">
            <div *ngFor="let module of activeRoute; let i=index" class="mb-2" [ngClass]="setColSize()">
                <h5 class="card-title">
                    <i class="fal fa-fw fa-1x" [ngClass]="module.icon || 'fa-folder-open'"></i>&nbsp;
                    <strong style="text-transform: capitalize;">{{module.title}}</strong>
                </h5>
                <hr class="mt-0 mb-0" />
                <ul class="list-group list-group-flush" (mouseleave)="mouseover=null">
                    <li *ngFor="let link of module.child; let j=index" class="list-group-item p-2 pointer"
                        (click)="$event.preventDefault(); onSelect(link)"
                        (mouseover)="mouseover=''+i+j" [class.active]="mouseover==''+i+j">
                        <i class="fa fa-fw fa-1x" [ngClass]="link.icon || 'fas fa-file-exclamation'"></i>&nbsp;
                        <span style="text-transform: capitalize;">{{link.title}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- <footer class="footer fixed-bottom bg-primary container-fluid" hidden>
    <div class="row">
        <p class="col text-muted">
            <a href="https://indecosoft.ro" target="_blank" rel="noopener noreferrer">Indeco Soft | Sisteme Informatice, Solutii Software</a>
        </p>
        <p class="col text-muted text-end">
            Suport şi contact <a href="https://www.indecosoft.ro/site/contact/" target="_blank" rel="noopener noreferrer">Indeco Soft</a>
        </p>
    </div>
</footer> -->