import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";
import { UserService, LogService, User, RoutingService } from "core";

@Component({
    selector: "login",
    templateUrl: "./login.html",
    styleUrls: ['./login.css']
})
export class Login implements OnInit, OnDestroy {
    constructor(private activatedRoute: ActivatedRoute,
        private router: Router, private http: HttpClient,
        private user: UserService, private log: LogService,
        protected routing: RoutingService) {
        this.isAdLogin = JSON.parse(localStorage.getItem('tipLogin')) || false;
        this.registration = <any>{};
    }

    protected action: string = 'login';
    protected isAdLogin: boolean = false;
    protected registration: User.IRegistration;

    protected isCapsOn: boolean;
    protected domainName: string;
    protected isDomainActive: boolean;
    protected isSocialActive: boolean;
    protected isRegisterActive: boolean;
    protected capsLockWarn: boolean;
    protected message = { success: '', error: '' };
    protected get isBusy() { return this.http['isBusy'] }

    private subscription: Subscription;
    private active = { register: false, domain: false, social: false };

    protected messageReset() { this.message.error = this.message.success = '' }
    protected setAdChoice() {
        localStorage.setItem('tipLogin', `${this.isAdLogin}`);
        this.messageReset();
    }

    goto(action: string) {
        this.messageReset();
        this.action = action;
    }
    ngOnDestroy() { this.subscription.unsubscribe() }
    ngOnInit() {
        // this.getLoginOptions() // TODO: activate this
        this.subscription = this.activatedRoute.paramMap.subscribe(paramMap => {
            this.registration.userName = paramMap['params']['username'];
            if (paramMap['params']['email-confirm']) {
                this.goto('login')
            } else if (paramMap['params']['reset-pwd']) {
                this.registration['hash'] = window.atob(decodeURIComponent(paramMap['params']['reset-pwd']));
                this.goto('newPassword');
            }
        });
    }

    @HostListener('window:click', ['$event'])
    @HostListener('window:keydown', ['$event'])
    @HostListener('window:keyup', ['$event'])
    onCapsCheck(event: MouseEvent | KeyboardEvent) {
        this.isCapsOn = !!(event.getModifierState && event.getModifierState('CapsLock'));
    }

    private getLoginOptions() {
        this.http.get('/auth/login/options').subscribe(response => {
            this.isRegisterActive = response['register'];
            this.isSocialActive = response['social'];
            this.isDomainActive = response['ad'];
            this.domainName = response['domainName'];
            this.capsLockWarn = response['capsLockWarn'];
        })
    }
    protected authenticate(provider: string): boolean {
        this.messageReset();
        // this.user.loginWithSatellizerToken(provider)
        //.subscribe(() => {
        //     this.message.success = "Se citesc drepturi de acces..."
        // }, (err) => {
        //     this.message.error = err.message || err.data
        // });
        return false;
    }

    protected login(): boolean {
        this.messageReset();
        this.registration.activeDirectory = this.isAdLogin;
        // TODO: activate this, make observable
        // this.user.login(this.registration).subscribe({
        //     next: () => {
        //         this.message.success = "Se citesc drepturi de acces...";
        //         this.router.navigate([localStorage.getItem('loginUrl') || 'home']);
        //     },
        //     error: (error) => {
        //         this.message.error = error.error || error.message || 'Utilizator sau parolă greșită!'
        //     }
        // });
        this.user.login(this.registration).then(() => {
            this.message.success = "Se citesc drepturi de acces...";
            this.router.navigate([localStorage.getItem('loginUrl') || 'home'], {});
        }, (error) => {
            this.message.error = error.error || error.message || 'Utilizator sau parolă greșită!'
        });
        return false;
    }

    protected register(): boolean {
        this.messageReset();
        this.user.register(this.registration).subscribe({
            next: (response) => {
                this.registration.userName = this.registration.userName;
                this.registration.password = "";
                const msg = (response ? "Pentru activare accesați-vă adresa de email." : "Acum vă puteți conecta.");
                this.message.success = 'Cont creat cu succes. '.concat(msg);
            },
            error: (error) => {
                this.message.error = error.error || error.message || 'Au apărut erori la creare cont!'
            }
        });
        return false;
    }

    protected passwordReset(): boolean {
        this.messageReset();
        this.user.resetPassword(this.registration).subscribe({
            next: () => { this.message.success = 'Solicitarea a fost trimisă cu success. Consultați-vă email-ul.' },
            error: (error) => { this.message.error = error.error || 'Au apărut erori la resetarea parolei!' }
        });
        return false;
    }

    protected newPassword(): boolean {
        this.messageReset();
        this.user.confirmResetPassword(this.registration).subscribe({
            error: (err) => { this.message.error = err.error || err.message || 'Au apărut erori la resetarea parolei!' },
            next: () => {
                this.log.success('Parola a fost resetată cu succes.');
                this.goto('login');
            },
        });
        return false;
    }
}