import { Component, HostListener } from "@angular/core";

@Component({
    selector: 'scroll-top',
    styleUrls: ['./scrollTop.scss'],
    template: `
    <button type="button" name="scrollTop" class="btn-info scroll-to-top"
        [ngClass]="animation" (click)="toTop()" aria-label="scroll to top">
        <i class="fa fa-2x fa-chevron-up"></i>
    </button>
`})
export class ScrollToTop {
    constructor() { }

    animation = "";
    toTop() { window.scrollTo({ top: 0, behavior: 'smooth' }) }

    // @HostListener('scroll', ['$event'])
    @HostListener('window:scroll', ['$event'])
    onScroll(event) { this.animation = window.scrollY > 300 ? 'show-scrollTop' : '' }
}