import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from "@angular/common/http";
import { catchError, filter, take, switchMap, finalize } from "rxjs/operators";
import { BehaviorSubject, from, Observable } from "rxjs";
import { UserService } from "core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private http: HttpClient, private user: UserService) { }

	// Refresh Token Subject tracks the current token, or is null if no token is currently available (e.g. refresh pending).
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private refreshTokenInProgress: boolean = false;

	private uuidv4() {
		return (<any>[1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
		)
	}

	private setAuthorization(request: HttpRequest<any>) {
		var token = this.user.token, options = {};
		if (token) {
			options['headers'] = request.headers.set('Authorization', `Bearer ${token}`);
			// .set('X-Request-ID', this.uuidv4());
		}
		const routeException = ~['/token', '/sigmaInit'].indexOf(request.url) || (request.method == 'GET' && ~request.url.indexOf('.html'));
		if (!routeException) { return request.clone(); }
		return request.clone(options);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		this.http['isBusy'] = true;
		var subscription;
		const routeException = ~['/token', '/sigmaInit', '/auth/token'].indexOf(req.url) || (req.method == 'GET' && ~req.url.indexOf('.html'));
		if (routeException) {
			subscription = next.handle(req)
		} else {
			subscription = from(Promise.resolve(this.user.setAuthorizationHeaders(req))).pipe(
				switchMap(res => next.handle(req))
			)
		}
		return subscription.pipe(
			finalize(() => { this.http['isBusy'] = false }),
			catchError(error => {
				return from(this.user.onResponseError(error));
				// if (error.status == 401) { this.user.logOut() } // activate only when removing ng1
				// throw error
			}),
		)
	}

	intercept2(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.http['isBusy'] = true;
		var request = this.setAuthorization(req);
		return next.handle(request).pipe(
			finalize(() => { this.http['isBusy'] = false }),
			catchError(error => {
				// We don't want to refresh token for some requests like login or refresh token itself
				// So we verify url and we throw an error if it's the case
				if (request.url.includes("token") || request.url.includes("login")) {
					this.user.logOutNew();
					throw error;
				} else if (error.status !== 401) {
					throw error
				}

				if (this.refreshTokenInProgress) {
					// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
					// – which means the new token is ready and we can retry the request again
					return this.refreshTokenSubject.pipe(
						filter(result => result !== null),
						take(1),
						switchMap(token => next.handle(this.addAuthenticationToken(request)))
					)
				} else {
					this.refreshTokenInProgress = true;
					// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
					this.refreshTokenSubject.next(null);

					// Call auth.refreshAccessToken(this is an Observable that will be returned)
					return this.user.refreshToken().pipe(
						switchMap(token => {
							// When the call to refreshToken completes we reset the refreshTokenInProgress to false
							// for the next time the token needs to be refreshed
							this.refreshTokenInProgress = false;
							this.refreshTokenSubject.next(token);
							return next.handle(this.addAuthenticationToken(request));
						}),
						catchError(error => {
							this.user.logOutNew();
							this.refreshTokenInProgress = false;
							throw error;
						})
					)
				}
			}))
	}

	private addAuthenticationToken(request) {
		// Get access token from Local Storage
		const accessToken = this.user.token;
		// If access token is null this means that user is not logged in
		if (!accessToken) { return request }
		// We clone the request, because the original request is immutable
		return request.clone({ headers: request.headers.set('Authorization', `Bearer ${accessToken}`), });
	}
}