import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'feedback-modal', templateUrl: './navbar.feedback.html'
})
export class FeedbackModal implements OnInit {
    constructor(private activeModal: NgbActiveModal, private http: HttpClient) { }

    page: string;
    modul: string;
    problema: string;
    private url: string;

    ngOnInit() { this.url = window.location.href }

    cancel() { this.activeModal.dismiss() }
    send() {
        this.http.post('/client/feedback', {
            url: this.url, problema: this.problema
        }).subscribe(res => this.activeModal.close())
    }
}